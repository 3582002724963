export const STATUS_LABELS = {
  active: {
    label: "Active",
    color: "success.main",
    textColor: "success.contrastText",
  },
  hidden: {
    label: "Closed",
    color: "default",
    textColor: "default",
  },
  archived: {
    label: "Archived",
    color: "info.light",
    textColor: "default",
  },
};
