import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import configureSentry from "utils/configureSentry";

// Import root app
import App from "containers/App";

// Import the AuthProvider
import AuthProvider from "providers/authProvider";

import {
  createTheme,
  CssBaseline,
  StyledEngineProvider,
  ThemeProvider,
} from "@mui/material";
import { SnackbarProvider } from "notistack";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

declare module "@mui/material/styles" {
  interface Palette {
    brand: Palette["primary"];
  }

  interface PaletteOptions {
    brand?: PaletteOptions["primary"];
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    brand: true;
  }
}

const theme = createTheme({
  palette: {
    brand: {
      main: "#9c5748",
      light: "#ccb1a9",
      dark: "#7c312a",
      contrastText: "#fff",
    },
    background: {
      default: "#fbf7f5",
      paper: "#fff",
    },
    primary: {
      main: "#111",
      light: "#333",
      dark: "#000",
      contrastText: "#fff",
    },
    secondary: {
      main: "#ff0080",
      light: "#ff77a9",
      dark: "#c60055",
      contrastText: "#fff",
    },
    success: {
      main: "#50e3c2",
      light: "#79ffe1",
      dark: "#29bc9b",
      contrastText: "#000",
    },
    info: {
      main: "#0070f3",
      light: "#3291ff",
      dark: "#0761d1",
      contrastText: "#fff",
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: "0 1px 0 0 rgba(0,0,0,0.1)",
          backgroundColor: "#fbf7f5",
          color: "#111",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 9999,
        },
      },
    },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <React.StrictMode>
    <StyledEngineProvider injectFirst>
      <AuthProvider>
        <SnackbarProvider
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
        >
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <App />
          </ThemeProvider>
        </SnackbarProvider>
      </AuthProvider>
    </StyledEngineProvider>
  </React.StrictMode>,
);

// Initialize Sentry
configureSentry();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
