import { IconButton, Tooltip, Typography } from "@mui/material";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { Link } from "react-router-dom";
import NewProductForm from "./newProductForm";

function NewProductPage() {
  return (
    <div>
      <div className="flex items-center mb-5">
        <Tooltip title="Back">
          <IconButton
            component={Link}
            to="/admin/products"
            sx={{ marginRight: 1 }}
            aria-label="delete"
          >
            <ArrowBackIcon />
          </IconButton>
        </Tooltip>

        <Typography variant="h6">Add product</Typography>
      </div>

      <NewProductForm />
    </div>
  );
}

export default NewProductPage;
