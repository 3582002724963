import TextField from "@mui/material/TextField";
import { Stack, Typography } from "@mui/material";
import Inquiry from "interfaces/inquiry";

interface Props {
  inquiry: Inquiry;
}

function InquiryDetail({ inquiry }: Props) {
  return (
    <Stack spacing={2}>
      <TextField
        label="Name"
        value={inquiry.name}
        InputProps={{
          readOnly: true,
        }}
      />

      <TextField
        label="Email"
        value={inquiry.email}
        InputProps={{
          readOnly: true,
        }}
      />

      <TextField
        label="Subject"
        value={inquiry.subject}
        InputProps={{
          readOnly: true,
        }}
      />

      <TextField
        label="Message"
        value={inquiry.message}
        InputProps={{
          readOnly: true,
        }}
        minRows={4}
        multiline
      />

      <Typography align="center">
        Submitted at: {new Date(inquiry.created_at).toLocaleString()}
      </Typography>
    </Stack>
  );
}

export default InquiryDetail;
