import { UserAPI } from "apis/userAPI";
import { LoaderFunctionArgs } from "react-router-dom";

export const loader = async ({ params }: LoaderFunctionArgs) => {
  const { userId } = params;

  if (!userId) {
    return null;
  }

  try {
    const response = await UserAPI.getUser(userId);

    return response.data.data;
  } catch (error) {
    console.error("Error fetching users: ", error);

    return null;
  }
};

export default loader;
