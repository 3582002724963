import { api } from "configs/axiosConfig";
import { isAxiosError } from "axios";

export const UserAPI = {
  login: async function (email: string, password: string) {
    return await api.request({
      url: `/users/login`,
      data: {
        email,
        password,
      },
      method: "POST",
    });
  },

  logout: async function () {
    return await api.delete("/users/logout");
  },

  getCurrentProfile: async function () {
    return await api.get("/users/me");
  },

  getAllUsers: async function () {
    return await api.get("/users");
  },

  getUser: async function (id: string) {
    return await api.get(`/users/${id}`);
  },

  createUser: async function (
    name: string,
    email: string,
    password: string,
    passwordConfirmation: string,
    role: string,
  ) {
    return await api.post("/users", {
      user: {
        name,
        email,
        password,
        password_confirmation: passwordConfirmation,
        role,
      },
    });
  },

  changePassword: async function (
    oldPassword: string,
    newPassword: string,
    newPasswordConfirmation: string,
  ) {
    try {
      return await api.put("/users/me/password", {
        password_challenge: oldPassword,
        password: newPassword,
        password_confirmation: newPasswordConfirmation,
      });
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        return error.response;
      }
    }
  },
};
