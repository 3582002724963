import { api } from "configs/axiosConfig";

export const InquiryAPI = {
  getAllInquiries: async function () {
    return await api.get("/inquiries");
  },

  getInquiry: async function (id: string) {
    return await api.get(`/inquiries/${id}`);
  },

  createInquiry: async function (data: FormData) {
    return await api.post("/inquiries", data);
  },

  updateInquiry: async function (id: number, status: string) {
    return await api.put(`/inquiries/${id}`, { status });
  },
};
