import { Link, useLoaderData } from "react-router-dom";
import Inquiry from "interfaces/inquiry";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Paper from "@mui/material/Paper";
import InquiryDetail from "./inquiryDetail";
import InquiryControl from "./inquiryControls";

function InquiryDetailPage() {
  const inquiry = useLoaderData() as Inquiry;

  return (
    <>
      <div className="flex items-center mb-5">
        <Tooltip title="Back">
          <IconButton
            component={Link}
            to="/admin/inquiries"
            sx={{ marginRight: 1 }}
            aria-label="delete"
          >
            <ArrowBackIcon />
          </IconButton>
        </Tooltip>

        <Typography variant="h6">Inquiry #{inquiry.id}</Typography>
      </div>

      <Box maxWidth="sm" margin="auto">
        <Paper sx={{ padding: 3 }}>
          <InquiryDetail inquiry={inquiry} />
        </Paper>

        <InquiryControl inquiry={inquiry} />
      </Box>
    </>
  );
}

export default InquiryDetailPage;
