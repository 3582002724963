import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import StyledTableCell from "../StyledTableCell";
import TableBody from "@mui/material/TableBody";
import { Link } from "react-router-dom";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import React from "react";
import Inquiry from "interfaces/inquiry";
import formatDate from "utils/formatDate";
import { STATUS_LABELS } from "./constants";
import { Chip } from "@mui/material";

interface Props {
  inquiries: Inquiry[];
}

function InquiryTable({ inquiries }: Props) {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Inquiry ID</StyledTableCell>
            <StyledTableCell>Client Name</StyledTableCell>
            <StyledTableCell>Email</StyledTableCell>
            <StyledTableCell>Status</StyledTableCell>
            <StyledTableCell align="right">Updated At</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {inquiries.map((inquiry) => (
            <TableRow
              hover
              component={Link}
              to={`/admin/inquiries/${inquiry.id}`}
              key={inquiry.id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {inquiry.id}
              </TableCell>
              <TableCell>{inquiry.name}</TableCell>
              <TableCell>{inquiry.email}</TableCell>
              <TableCell>
                <Chip
                  label={STATUS_LABELS[inquiry.status].label}
                  sx={{ backgroundColor: STATUS_LABELS[inquiry.status].color }}
                />
              </TableCell>
              <TableCell align="right">
                {formatDate(inquiry.updated_at)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default InquiryTable;
