import { InquiryAPI } from "apis/inquiryAPI";
import { LoaderFunctionArgs } from "react-router-dom";

export const loader = async ({ params }: LoaderFunctionArgs) => {
  const { inquiryId } = params;

  if (!inquiryId) {
    return null;
  }

  try {
    const response = await InquiryAPI.getInquiry(inquiryId);

    return response.data.data;
  } catch (error) {
    console.error("Error fetching inquiry: ", error);

    return null;
  }
};

export default loader;
