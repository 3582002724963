import React from "react";
import { Typography } from "@mui/material";

function AdminHomePage() {
  return (
    <div className="flex justify-center mb-5">
      <Typography variant="h6" sx={{ flexGrow: 1 }}>
        Home
      </Typography>
    </div>
  );
}

export default AdminHomePage;
