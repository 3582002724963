import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import StyledTableCell from "components/StyledTableCell";
import Product from "interfaces/product";
import { Link } from "react-router-dom";
import { Chip } from "@mui/material";
import { STATUS_LABELS } from "./constants";

interface Props {
  products: Product[];
}

function ProductTable({ products }: Props) {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <StyledTableCell />
            <StyledTableCell>Product ID</StyledTableCell>
            <StyledTableCell>Name</StyledTableCell>
            <StyledTableCell>Status</StyledTableCell>
            <StyledTableCell>Category</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {products.map((product) => (
            <TableRow
              hover
              component={Link}
              to={`/admin/products/${product.id}`}
              key={product.id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell sx={{ width: 100 }}>
                <img
                  src={"https://placehold.co/100x100"}
                  alt={product.name}
                  className="mr-2 rounded border"
                />
              </TableCell>
              <TableCell component="th" scope="row">
                {product.id}
              </TableCell>
              <TableCell>{product.name}</TableCell>
              <TableCell>
                <Chip
                  label={STATUS_LABELS[product.status].label}
                  sx={{
                    backgroundColor: STATUS_LABELS[product.status].color,
                    color: STATUS_LABELS[product.status].textColor,
                  }}
                />
              </TableCell>
              <TableCell>{product.category || "No Category"}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default ProductTable;
