import {
  Button,
  ImageList,
  ImageListItem,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Link } from "react-router-dom";

import project1 from "assets/project-1.jpg";
import project2 from "assets/project-2.jpg";
import project3 from "assets/project-3.jpg";
import project4 from "assets/project-4.jpg";

const itemData = [
  {
    img: project1,
    title: "Project 1",
  },
  {
    img: project2,
    title: "Project 2",
  },
  {
    img: project3,
    title: "Project 3",
  },
  {
    img: project4,
    title: "Project 4",
  },
];

function HomeProjectShowcase() {
  const matches = useMediaQuery("(min-width:600px)");

  return (
    <div className="px-2 flex flex-col container m-auto">
      <ImageList cols={matches ? 4 : 2} rowHeight={400}>
        {itemData.map((item) => (
          <ImageListItem key={item.img}>
            <img
              src={item.img}
              alt={item.title}
              className="object-cover w-full h-full rounded-lg"
              loading="lazy"
            />
          </ImageListItem>
        ))}
      </ImageList>

      <Typography className="text-center my-10 uppercase text-3xl">
        From Landscaping to Kitchen Countertops
      </Typography>

      <Button
        component={Link}
        to="/catalogue"
        variant="contained"
        color="brand"
        size="large"
        className="m-auto"
      >
        View Our Catalogue
      </Button>
    </div>
  );
}

export default HomeProjectShowcase;
