import ChangePasswordForm from "./changePasswordForm";
import { IconButton, Tooltip, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import React from "react";

function ChangePasswordPage() {
  return (
    <>
      <div className="flex items-center mb-5">
        <Tooltip title="Back">
          <IconButton
            component={Link}
            to="/admin"
            sx={{ marginRight: 1 }}
            aria-label="delete"
          >
            <ArrowBackIcon />
          </IconButton>
        </Tooltip>

        <Typography variant="h6">Change Password</Typography>
      </div>

      <ChangePasswordForm />
    </>
  );
}

export default ChangePasswordPage;
