import { UserAPI } from "apis/userAPI";

export const loader = async () => {
  try {
    const response = await UserAPI.getAllUsers();

    return response.data.data;
  } catch (error) {
    console.error("Error fetching users: ", error);

    return null;
  }
};
