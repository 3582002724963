import mingstoneCatalogue from "assets/catalogue.pdf";
import mingstoneCatalogueCover from "assets/catalogue-cover.png";
import mingstoneVeneerLedgePanel from "assets/ming-stone-veneer-ledge-panel.pdf";
import mingstoneVeneerLedgePanelCover from "assets/ming-stone-veneer-ledge-panel-cover.png";
import CatalogueFileListItem from "components/CatalogueFileListItem";

function CataloguePage() {
  return (
    <div className="my-10 mx-auto px-2 space-y-10 max-w-5xl">
      <CatalogueFileListItem
        coverImage={mingstoneCatalogueCover}
        title="Main Catalogue"
        description="Browse our catalogue to discover our wide selection of natural stone products. From granite to marble, we have the perfect stone for your next project."
        link={mingstoneCatalogue}
      />
      <CatalogueFileListItem
        coverImage={mingstoneVeneerLedgePanelCover}
        title="Veneer & Ledge Panel"
        description="Ming Stone offers a wide selection of veneer and ledge panels for your next project. Our natural stone veneer and ledge panels are perfect for both interior and exterior applications."
        link={mingstoneVeneerLedgePanel}
      />
    </div>
  );
}

export default CataloguePage;
