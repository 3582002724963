import { api } from "configs/axiosConfig";

import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import UserProfile from "../interfaces/userProfile";
import { UserAPI } from "../apis/userAPI";

interface Props {
  children: React.ReactNode;
}

interface IAuthContext {
  loaded: boolean;
  userProfile: UserProfile | null;
  fetchUserProfile: () => Promise<void>;
  removeUserProfile: () => void;
}

const initialAuthContext = {
  loaded: false,
  userProfile: null,
  fetchUserProfile: () => Promise.resolve(),
  removeUserProfile: () => {},
};

const AuthContext = createContext<IAuthContext>(initialAuthContext);

const AuthProvider = ({ children }: Props) => {
  const [userProfile, setUserProfile] = useState<UserProfile | null>(null);
  const [loaded, setLoaded] = useState(false);

  const fetchUserProfile = useCallback(async () => {
    try {
      const response = await UserAPI.getCurrentProfile();
      setUserProfile(response.data.data);
    } catch (error) {
      console.error("Error fetching user profile: ", error);
    }
  }, []);

  const removeUserProfile = useCallback(() => {
    setUserProfile(null);
  }, []);

  useEffect(() => {
    api.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error.response.status === 401) {
          setUserProfile(null);
        }
        return Promise.reject(error);
      },
    );
  }, []);

  useEffect(() => {
    fetchUserProfile().then(() => {
      setLoaded(true);
    });
  }, [fetchUserProfile]);

  const contextValue = useMemo(
    () => ({
      loaded,
      userProfile,
      fetchUserProfile,
      removeUserProfile,
    }),
    [loaded, userProfile, fetchUserProfile, removeUserProfile],
  );

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};

export default AuthProvider;
