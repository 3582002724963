import { Box, Button, Stack, Typography } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import useDropzone from "../../hooks/useDropzone";
import { alpha } from "@mui/material";
import { useEffect } from "react";

interface Props {
  name?: string;
  onChange?: (files: File[]) => void;
}

function FileUploader({ onChange, name }: Props) {
  const {
    files,
    isDragging,
    handleDragEnter,
    handleDragLeave,
    handleDrop,
    handleDragOver,
    handleChange,
  } = useDropzone();

  useEffect(() => {
    onChange?.(files);
  }, [files, onChange]);

  return (
    <Box
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      onDragOver={handleDragOver}
    >
      <Button
        fullWidth
        sx={{
          borderRadius: "8px",
          borderWidth: "2px",
          borderStyle: "dashed",
          backgroundColor: isDragging ? alpha("#111", 0.04) : "transparent",
        }}
        component="label"
      >
        <Stack alignItems="center" spacing={4} my={5}>
          <Typography textTransform="none">
            {isDragging
              ? "Drop files here"
              : "Drag and drop or click to select files"}
          </Typography>
          <CloudUploadIcon />

          <input
            type="file"
            name={name}
            onChange={handleChange}
            multiple
            hidden
          />
        </Stack>
      </Button>
    </Box>
  );
}

export default FileUploader;
