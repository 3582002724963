import { api } from "configs/axiosConfig";

export const ProductAPI = {
  getAllProducts: async function () {
    return await api.get("/products");
  },

  getProduct: async function (id: string) {
    return await api.get(`/products/${id}`);
  },

  createProduct: async function (data: FormData) {
    return await api.post("/products", data);
  },
};
