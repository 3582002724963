import { Typography } from "@mui/material";
import InquiryTable from "components/InquiryTable";
import { useLoaderData } from "react-router-dom";
import Inquiry from "interfaces/inquiry";

function InquiryPage() {
  const inquiries = useLoaderData() as Inquiry[];

  return (
    <div>
      <div className="flex justify-center mb-5">
        <Typography variant="h6" sx={{ flexGrow: 1 }}>
          Inquiries
        </Typography>
      </div>

      <InquiryTable inquiries={inquiries} />
    </div>
  );
}

export default InquiryPage;
