import React from "react";

import {
  Box,
  Container,
  Divider,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import ContactInformation from "components/ContactInformation";
import ContactForm from "components/ContactForm";

function ContactPage() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box margin="auto" maxWidth="md" marginY={10}>
      <Container
        sx={{ display: "flex", flexDirection: { xs: "column", sm: "row" } }}
      >
        <Box flexGrow={1}>
          <ContactForm />
        </Box>

        {!isSmallScreen && (
          <>
            <Divider orientation="vertical" flexItem sx={{ marginX: 10 }} />
            <ContactInformation />
          </>
        )}
      </Container>
    </Box>
  );
}

export default ContactPage;
