import { Outlet } from "react-router-dom";
import NavBar from "components/NavBar";
import UnderConstruction from "../../components/UnderConstruction";
import Footer from "components/Footer";

function AppLayout() {
  if (process.env.REACT_APP_CONSTRUCTION_MODE === "true") {
    return <UnderConstruction />;
  }

  return (
    <>
      <NavBar />
      <main>
        <Outlet />
      </main>
      <Footer />
    </>
  );
}

export default AppLayout;
