import React from "react";

import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import UserTable from "components/UserTable";

import { Link, useLoaderData } from "react-router-dom";
import UserProfile from "interfaces/userProfile";

function UserPage() {
  const users = useLoaderData() as UserProfile[];

  return (
    <div>
      <div className="flex justify-center mb-5">
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          Users
        </Typography>
        <div>
          <Button
            component={Link}
            to="/admin/users/new"
            size="small"
            variant="contained"
          >
            Add User
          </Button>
        </div>
      </div>

      <UserTable users={users} />
    </div>
  );
}

export default UserPage;
