import { Typography } from "@mui/material";

import counterTop from "assets/counter-top.jpg";
import tumbledStones from "assets/tumbled-stones.png";

function HomeAbout() {
  return (
    <section>
      <div className="gap-16 items-center py-8 px-4 mx-auto max-w-screen-xl lg:grid lg:grid-cols-2 lg:py-16 lg:px-6">
        <div className="font-light text-gray-500 sm:text-lg">
          <Typography className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900">
            About Ming Stone
          </Typography>
          <Typography variant="body1" marginBottom={2}>
            Established in 1993, Ming Stone is a premier natural stone supplier,
            sourcing exquisite granite, marble, sandstone, slate, limestone, and
            travertine for clients worldwide.
          </Typography>

          <Typography variant="body1">
            We specialize in authentic natural stone from renowned producers,
            ensuring unparalleled quality and character. Our dedicated quality
            control team guarantees integrity and aesthetic perfection. With
            careful consideration of unique stone properties, we elevate
            projects through harmonious blends of function, durability, and
            breathtaking natural beauty.
          </Typography>
        </div>
        <div className="grid grid-cols-2 gap-4 mt-8">
          <img
            className="w-full h-96 object-cover rounded-lg"
            src={counterTop}
            alt="counter top"
          />
          <img
            className="mt-4 w-full h-96 object-cover lg:mt-10 rounded-lg"
            src={tumbledStones}
            alt="tumbled stones"
          />
        </div>
      </div>
    </section>
  );
}

export default HomeAbout;
