import {
  Button,
  Card,
  CardContent,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  TextField,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Link, useNavigate } from "react-router-dom";
import React from "react";
import { UserAPI } from "apis/userAPI";
import { useSnackbar } from "notistack";

function NewUserPage() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [passwordConfirmation, setPasswordConfirmation] = React.useState("");
  const [role, setRole] = React.useState("user");

  const formRef = React.useRef<HTMLFormElement | null>(null);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    UserAPI.createUser(name, email, password, passwordConfirmation, role)
      .then((response) => {
        if (response.status === 201) {
          enqueueSnackbar("User created successfully", {
            variant: "success",
          });
          navigate("/admin/users");
        }
      })
      .catch(() => {
        enqueueSnackbar("Failed to create user", {
          variant: "error",
        });
      });
  };

  return (
    <div>
      <div className="flex items-center mb-5">
        <Tooltip title="Back">
          <IconButton
            component={Link}
            to="/admin/users"
            sx={{ marginRight: 1 }}
            aria-label="delete"
          >
            <ArrowBackIcon />
          </IconButton>
        </Tooltip>

        <Typography variant="h6">New User</Typography>
      </div>

      <Stack m="auto" maxWidth="450px" spacing={2}>
        <Card>
          <CardContent>
            <Stack
              component="form"
              onSubmit={handleSubmit}
              spacing={3}
              ref={formRef}
            >
              <Typography variant="subtitle1">User Information</Typography>

              <TextField
                label="Name"
                name="name"
                margin="normal"
                required
                fullWidth
                onChange={(event) => setName(event.target.value)}
              />

              <TextField
                label="Email"
                type="email"
                name="email"
                required
                fullWidth
                onChange={(event) => setEmail(event.target.value)}
              />

              <TextField
                label="Password"
                type="password"
                name="password"
                required
                fullWidth
                onChange={(event) => setPassword(event.target.value)}
              />

              <TextField
                label="Password Confirmation"
                type="password"
                name="password_confirmation"
                required
                fullWidth
                onChange={(event) =>
                  setPasswordConfirmation(event.target.value)
                }
              />

              <TextField
                select
                fullWidth
                required
                name="role"
                label="Role"
                SelectProps={{
                  native: true,
                }}
                helperText="Please select the user role"
                value={role}
                onChange={(event) => setRole(event.target.value)}
              >
                <option key="user" value="user">
                  User
                </option>
                <option key="admin" value="admin">
                  Admin
                </option>
              </TextField>
            </Stack>
          </CardContent>
        </Card>

        <Button
          variant="contained"
          onClick={() => {
            formRef.current?.requestSubmit();
          }}
        >
          Create
        </Button>
      </Stack>
    </div>
  );
}

export default NewUserPage;
