import {
  createBrowserRouter,
  isRouteErrorResponse,
  useRouteError,
} from "react-router-dom";

// Pages
import HomePage from "containers/HomePage";
import ContactPage from "containers/ContactPage";
import CataloguePage from "containers/CataloguePage";
import AdminHomePage from "containers/AdminHomePage";
import ProductPage from "containers/ProductPage";
import ProductDetailPage from "containers/ProductDetailPage";
import UserPage from "containers/UserPage";
import InquiryPage from "containers/InquiryPage";
import LoginPage from "containers/LoginPage";
import NoMatchPage from "containers/NoMatchPage";
import UserProfilePage from "containers/UserProfilePage";
import NewUserPage from "containers/NewUserPage";
import NewProductPage from "containers/NewProductPage";
import InquiryDetailPage from "containers/InquiryDetailPage";
import ChangePasswordPage from "containers/ChangePasswordPage";
import GalleryPage from "containers/GalleryPage";

// Layouts
import AppLayout from "layouts/AppLayout";
import RoleLayout from "../layouts/RoleLayout";
import AdminLayout from "layouts/AdminLayout";

// Loaders
import { loader as userPageLoader } from "containers/UserPage/loader";
import { loader as userProfilePageLoader } from "containers/UserProfilePage/loader";
import { loader as inquiryPageLoader } from "containers/InquiryPage/loader";
import { loader as inquiryDetailPageLoader } from "containers/InquiryDetailPage/loader";
import { loader as productPageLoader } from "containers/ProductPage/loader";
import { loader as productDetailPageLoader } from "containers/ProductDetailPage/loader";

function RootBoundary() {
  const error = useRouteError();

  if (isRouteErrorResponse(error)) {
    if (error.status === 404) {
      return <NoMatchPage />;
    }

    if (error.status === 401) {
      return <div>You aren't authorized to see this</div>;
    }

    if (error.status === 503) {
      return <div>Looks like our API is down</div>;
    }

    if (error.status === 418) {
      return <div>🫖</div>;
    }
  }

  return <div>Something went wrong</div>;
}

export const router = createBrowserRouter([
  {
    errorElement: <RootBoundary />,
    children: [
      {
        Component: AppLayout,
        children: [
          { path: "/", Component: HomePage },
          { path: "/catalogue", Component: CataloguePage },
          { path: "/gallery", Component: GalleryPage },
          { path: "/contact", Component: ContactPage },
        ],
      },
      {
        Component: AdminLayout,
        children: [
          {
            path: "/admin",
            children: [
              { index: true, Component: AdminHomePage },
              {
                path: "products",
                Component: ProductPage,
                loader: productPageLoader,
              },
              { path: "products/new", Component: NewProductPage },
              {
                path: "products/:productId",
                Component: ProductDetailPage,
                loader: productDetailPageLoader,
              },
              {
                element: <RoleLayout roles={["admin"]} />,
                children: [
                  {
                    path: "users",
                    Component: UserPage,
                    loader: userPageLoader,
                  },
                  {
                    path: "users/new",
                    Component: NewUserPage,
                  },
                  {
                    path: "users/:userId",
                    Component: UserProfilePage,
                    loader: userProfilePageLoader,
                  },
                ],
              },
              {
                path: "inquiries",
                Component: InquiryPage,
                loader: inquiryPageLoader,
              },
              {
                path: "inquiries/:inquiryId",
                Component: InquiryDetailPage,
                loader: inquiryDetailPageLoader,
              },
              {
                path: "change-password",
                Component: ChangePasswordPage,
              },
            ],
          },
        ],
      },
      { path: "/login", Component: LoginPage },
      { path: "*", Component: NoMatchPage },
    ],
  },
]);
