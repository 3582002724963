import React, { useRef } from "react";
import { InquiryAPI } from "../../apis/inquiryAPI";
import { Box, Button, Stack, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import { useSnackbar } from "notistack";

function ContactForm() {
  const { enqueueSnackbar } = useSnackbar();

  const formRef = useRef<HTMLFormElement | null>(null);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formData = new FormData(e.currentTarget);

    InquiryAPI.createInquiry(formData)
      .then((response) => {
        if (response.status === 201) {
          enqueueSnackbar("Inquiry sent successfully", {
            variant: "success",
          });
          formRef.current?.reset();
        }
      })
      .catch((error) => {
        console.error(error);
        enqueueSnackbar("Failed to send inquiry", {
          variant: "error",
        });
      });
  };

  return (
    <>
      <Typography variant="h5">Contact Us</Typography>

      <Box
        component="form"
        ref={formRef}
        onSubmit={handleSubmit}
        sx={{
          "& > :not(style)": { my: 2 },
        }}
        autoComplete="off"
      >
        <Stack spacing={2} direction="row" sx={{ marginBottom: 4 }}>
          <TextField
            label="Name"
            variant="standard"
            name="inquiry[name]"
            required
            fullWidth
          />
          <TextField
            label="Email"
            variant="standard"
            name="inquiry[email]"
            required
            fullWidth
          />
        </Stack>

        <TextField
          label="Subject"
          variant="standard"
          name="inquiry[subject]"
          required
          fullWidth
        />
        <TextField
          label="Message"
          variant="standard"
          name="inquiry[message]"
          required
          fullWidth
          multiline
          rows={5}
        />

        <Box mt={2}>
          <Typography variant="caption">
            We'll get back to you as soon as possible.
          </Typography>
        </Box>

        <Button variant="contained" color="brand" type="submit" fullWidth>
          Send
        </Button>
      </Box>
    </>
  );
}

export default ContactForm;
