import Button from "@mui/material/Button";
import { CircularProgress } from "@mui/material";

interface Props {
  label: string;
  loading: boolean;
}

function LoadingButton({ label, loading }: Props) {
  return (
    <Button
      type="submit"
      fullWidth
      variant="contained"
      color="primary"
      disabled={loading}
      sx={{ mt: 3, mb: 2, height: 40 }}
    >
      {loading ? <CircularProgress size={30} /> : label}
    </Button>
  );
}

export default LoadingButton;
