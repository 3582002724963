import HomeAbout from "components/HomeAbout";
// import HomeCatalogue from "components/HomeCatalogue";
import HomeHero from "components/HomeHero";
import HomeProjectShowcase from "components/HomeProjectShowcase";

function HomePage() {
  return (
    <>
      <HomeHero />
      {/* <HomeCatalogue /> */}
      <HomeAbout />
      <HomeProjectShowcase />
    </>
  );
}

export default HomePage;
