import { InquiryAPI } from "apis/inquiryAPI";

export const loader = async () => {
  try {
    const response = await InquiryAPI.getAllInquiries();

    return response.data.data;
  } catch (error) {
    console.error("Error fetching inquiries: ", error);

    return null;
  }
};

export default loader;
