import ProjectGallery from "components/ProjectGallery";
import { Box } from "@mui/material";

function GalleryPage() {
  return (
    <>
      <Box py={5}>
        <ProjectGallery />
      </Box>
    </>
  );
}

export default GalleryPage;
