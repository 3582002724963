import { Button, Stack } from "@mui/material";
import Inquiry from "interfaces/inquiry";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { InquiryAPI } from "apis/inquiryAPI";

interface Props {
  inquiry: Inquiry;
}

function InquiryControl({ inquiry }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const handleSolve = () => {
    InquiryAPI.updateInquiry(inquiry.id, "closed")
      .then(() => {
        enqueueSnackbar("Inquiry has been marked as resolved", {
          variant: "success",
        });
        navigate("/admin/inquiries");
      })
      .catch(() => {
        enqueueSnackbar("Failed to mark inquiry as resolved", {
          variant: "error",
        });
      });
  };

  const handleActive = () => {
    InquiryAPI.updateInquiry(inquiry.id, "active")
      .then(() => {
        enqueueSnackbar("Inquiry has been marked as active", {
          variant: "success",
        });
        navigate("/admin/inquiries");
      })
      .catch(() => {
        enqueueSnackbar("Failed to mark inquiry as active", {
          variant: "error",
        });
      });
  };

  return (
    <Stack spacing={2} marginTop={2} direction="row">
      <Button
        variant="contained"
        href={`mailto:${inquiry.email}?subject=Re: ${inquiry.subject}`}
      >
        Reply
      </Button>

      {inquiry.status === "active" ? (
        <Button variant="contained" color="success" onClick={handleSolve}>
          Mark as Resolved
        </Button>
      ) : (
        <Button variant="outlined" onClick={handleActive}>
          Mark as Active
        </Button>
      )}
    </Stack>
  );
}

export default InquiryControl;
