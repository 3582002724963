import React from "react";
import {
  Card,
  CardContent,
  ImageList,
  ImageListItem,
  Stack,
  Typography,
} from "@mui/material";
import FileUploader from "components/FileUploader";

interface Props {
  images: File[];
  setImages: (images: File[]) => void;
}

function ProductImageCard({ images, setImages }: Props) {
  const handleImageChange = (files: File[]) => {
    setImages(files);
  };

  return (
    <Card>
      <CardContent>
        <Typography mb={2} variant="subtitle1">
          Product Images
        </Typography>

        <Stack spacing={3}>
          <FileUploader onChange={handleImageChange} />

          <ImageList cols={4} sx={{ overflowY: "visible" }}>
            {images.map((image) => (
              <ImageListItem key={image.name}>
                <img
                  src={URL.createObjectURL(image)}
                  alt={image.name}
                  loading="lazy"
                  className="!object-contain aspect-square rounded-md border border-gray-200 w-full h-full"
                />
              </ImageListItem>
            ))}
          </ImageList>
        </Stack>
      </CardContent>
    </Card>
  );
}

export default ProductImageCard;
