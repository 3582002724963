import { Link, useLoaderData } from "react-router-dom";
import Product from "interfaces/product";
import { IconButton, Tooltip, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import React from "react";

function ProductDetailPage() {
  const product = useLoaderData() as Product;

  return (
    <>
      <div className="flex items-center mb-5">
        <Tooltip title="Back">
          <IconButton
            component={Link}
            to="/admin/products"
            sx={{ marginRight: 1 }}
            aria-label="delete"
          >
            <ArrowBackIcon />
          </IconButton>
        </Tooltip>

        <Typography variant="h6">{product.name}</Typography>
      </div>
    </>
  );
}

export default ProductDetailPage;
