import { LoaderFunctionArgs } from "react-router-dom";
import { ProductAPI } from "apis/productAPI";

export const loader = async ({ params }: LoaderFunctionArgs) => {
  const { productId } = params;

  if (!productId) {
    return null;
  }

  try {
    const response = await ProductAPI.getProduct(productId);

    return response.data.data;
  } catch (error) {
    console.error("Error fetching users: ", error);

    return null;
  }
};
