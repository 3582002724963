import ProductTable from "components/ProductTable";
import Button from "@mui/material/Button";
import { Typography } from "@mui/material";
import { Link, useLoaderData } from "react-router-dom";
import Product from "interfaces/product";

function ProductPage() {
  const products = useLoaderData() as Product[];

  return (
    <div>
      <div className="flex justify-center mb-5">
        <Typography variant="h6" sx={{ flexGrow: 1 }}>
          Products
        </Typography>
        <div>
          <Button
            component={Link}
            to="/admin/products/new"
            size="small"
            variant="contained"
          >
            Add Product
          </Button>
        </div>
      </div>

      <ProductTable products={products} />
    </div>
  );
}

export default ProductPage;
