import React from "react";
import {
  Box,
  CssBaseline,
  IconButton,
  Toolbar,
  Typography,
  Divider,
  List,
} from "@mui/material";

import AppBar from "./appBar";
import Drawer from "./drawer";
import Avatar from "./avatar";

import MenuIcon from "@mui/icons-material/Menu";
import { MainListItems, secondaryListItems } from "./adminListItems";
import useLocalStorage from "hooks/useLocalStorage";
import UserProfile from "interfaces/userProfile";

import logo from "assets/logo.jpg";

interface Props {
  children: React.ReactNode;
  userProfile?: UserProfile;
}

function AdminNavBar(props: Props) {
  const [open, setOpen] = useLocalStorage("adminDrawer", false);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="absolute" open={open}>
        <Toolbar
          sx={{
            pr: "24px", // keep right padding when drawer closed
          }}
        >
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer}
            sx={{
              marginRight: "36px",
            }}
          >
            <MenuIcon />
          </IconButton>

          <img src={logo} alt="logo" className="w-10 h-10 mr-2" />
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            sx={{ flexGrow: 1 }}
          >
            Dashboard
          </Typography>
          <div className="ml-2">
            <Avatar userProfile={props.userProfile} />
          </div>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <Toolbar />
        <List component="nav">
          <MainListItems />
          <Divider sx={{ my: 1 }} />
          {secondaryListItems}
        </List>
      </Drawer>
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: "100vh",
          overflow: "auto",
        }}
      >
        <Toolbar />
        <Box sx={{ my: 3, mx: 4 }}>{props.children}</Box>
      </Box>
    </Box>
  );
}

export default AdminNavBar;
