import React from "react";
import UserRole from "interfaces/userRole";
import { useAuth } from "providers/authProvider";
import LoadingPage from "containers/LoadingPage";
import { Outlet } from "react-router-dom";

interface Props {
  roles: UserRole[];
}

function RoleLayout({ roles }: Props) {
  const { loaded, userProfile } = useAuth();

  const canAccess = roles.includes(userProfile?.role as UserRole);

  if (!loaded) {
    return <LoadingPage />;
  } else if (!canAccess) {
    return <div>Access Denied</div>;
  }

  return <Outlet />;
}

export default RoleLayout;
