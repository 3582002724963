import { Button } from "@mui/material";
import CallIcon from "@mui/icons-material/Call";
import { Link } from "react-router-dom";
import heroBackground from "assets/hero-background.jpg";

function HomeHero() {
  return (
    <section className="relative flex h-screen">
      <div className="grid max-w-screen-xl px-4 py-16 mx-auto lg:gap-8 xl:gap-0 lg:py-32 lg:grid-cols-12">
        <div className="mr-auto place-self-center lg:col-span-7">
          <h1 className="max-w-2xl mb-4 text-4xl font-extrabold tracking-tight leading-none text-primary-100 md:text-5xl xl:text-6xl">
            Over 30 Years of Natural Stone Excellence
          </h1>
          <p className="max-w-2xl mb-6 font-light text-white lg:mb-8 md:text-lg lg:text-xl">
            From the Heart of Nature: Discover the Distinction of Our Granite,
            Marble, Sandstone, Limestone, Slate and More.
          </p>
          <Button
            component={Link}
            to="/contact"
            variant="contained"
            color="brand"
            size="large"
          >
            <CallIcon className="mr-2" />
            <div>Contact Us</div>
          </Button>
        </div>
      </div>

      <img
        className="absolute w-full h-screen object-cover -z-20"
        src={heroBackground}
        alt="hero background image"
      />
      <div className="absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden bg-gradient-to-r from-black/70 via-black/20 to-black/70 bg-fixed -z-10"></div>
    </section>
  );
}

export default HomeHero;
