export const STATUS_LABELS = {
  active: {
    label: "Active",
    color: "success.main",
  },
  closed: {
    label: "Closed",
    color: "info.light",
  },
};
