import TextField from "@mui/material/TextField";
import { Box, Button, Card, CardContent, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { UserAPI } from "apis/userAPI";
import { useSnackbar } from "notistack";

function ChangePasswordForm() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);

    const currentPassword = formData.get("password_challenge") as string;
    const newPassword = formData.get("password") as string;
    const passwordConfirmation = formData.get(
      "password_confirmation",
    ) as string;

    UserAPI.changePassword(
      currentPassword,
      newPassword,
      passwordConfirmation,
    ).then((response) => {
      if (response?.status === 200) {
        enqueueSnackbar("Password changed successfully", {
          variant: "success",
        });
        navigate("/admin");
      }
    });
  };

  return (
    <Box margin="auto" maxWidth="400px">
      <form onSubmit={handleSubmit}>
        <Card sx={{ paddingY: 1 }}>
          <CardContent>
            <Stack spacing={3}>
              <TextField
                fullWidth
                id="outlined-basic"
                variant="outlined"
                label="Current Password"
                type="password"
                name="password_challenge"
              />

              <TextField
                fullWidth
                id="outlined-basic"
                variant="outlined"
                label="New Password"
                type="password"
                name="password"
              />

              <TextField
                fullWidth
                id="outlined-basic"
                variant="outlined"
                label="Confirm New Password"
                type="password"
                name="password_confirmation"
              />
            </Stack>
          </CardContent>
        </Card>

        <Button
          sx={{ marginTop: 2 }}
          type="submit"
          variant="contained"
          fullWidth
        >
          Change Password
        </Button>
      </form>
    </Box>
  );
}

export default ChangePasswordForm;
