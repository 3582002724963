import React, { useState } from "react";

const useDropzone = () => {
  const [files, setFiles] = useState<File[]>([]);
  const [isDragging, setIsDragging] = useState(false);

  const handleDragEnter = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();

    setIsDragging(true);
  };

  const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();

    setIsDragging(false);
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();

    setIsDragging(true);
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();

    setIsDragging(false);
    const newFiles = Array.from(event.dataTransfer.files);

    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    event.stopPropagation();

    const newFiles = Array.from(event.target.files || []);

    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  return {
    files,
    isDragging,
    handleDragEnter,
    handleDragLeave,
    handleDrop,
    handleDragOver,
    handleChange,
  };
};

export default useDropzone;
