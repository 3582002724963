import { Link } from "react-router-dom";

import { Button } from "@mui/material";
import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";

function Footer() {
  return (
    <footer className="w-full max-w-screen-xl mx-auto p-4 md:py-8">
      <div className="sm:flex sm:items-end sm:justify-between">
        <div>
          <Link
            to="/"
            className="flex items-center mb-4 sm:mb-4 space-x-3 rtl:space-x-reverse"
          >
            <span className="self-center text-2xl font-medium whitespace-nowrap">
              Ming Stone
            </span>
          </Link>

          <ul className="flex flex-wrap justify-start items-center mb-6 text-sm font-medium text-gray-500 sm:mb-0">
            <li>
              <Link to="/" className="hover:text-primary-200 me-4 md:me-6">
                Home
              </Link>
              <Link
                to="/catalogue"
                className="hover:text-primary-200 me-4 md:me-6"
              >
                Catalogue
              </Link>
            </li>
            <li>
              <Link to="/contact" className="hover:text-primary-200">
                Contact
              </Link>
            </li>
          </ul>
        </div>

        <div>
          <Button
            href="tel: +16047917855"
            startIcon={<CallIcon sx={{ color: "brand.main" }} />}
          >
            +1 (604) 791-7855
          </Button>

          <Button
            href="mailto: admin@mingstone.com"
            startIcon={<EmailIcon sx={{ color: "brand.main" }} />}
          >
            mingstonesupplier@gmail.com
          </Button>
        </div>
      </div>

      <hr className="my-6 border-gray-200 sm:mx-auto lg:my-8" />
      <span className="block text-sm text-gray-500 sm:text-centeR">
        © 2024{" "}
        <Link to="/" className="hover:text-primary-200">
          Ming Stone
        </Link>
        . All Rights Reserved.
      </span>
    </footer>
  );
}

export default Footer;
