import { Stack, Typography } from "@mui/material";
import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";

function ContactInformation() {
  return (
    <Stack spacing={2} margin="auto">
      <Typography sx={{ fontWeight: "medium" }}>Call us</Typography>
      <Stack direction="row" spacing={2} alignItems="center">
        <CallIcon sx={{ color: "brand.main" }} />
        <Typography>
          <a href="tel: +16047917855">+1 (604) 791-7855</a>
        </Typography>
      </Stack>

      <Typography sx={{ fontWeight: "medium" }}>Email us</Typography>
      <Stack direction="row" spacing={2} alignItems="center">
        <EmailIcon sx={{ color: "brand.main" }} />
        <Typography>
          <a href="mailto: admin@mingstone.com">mingstonesupplier@gmail.com</a>
        </Typography>
      </Stack>
    </Stack>
  );
}

export default ContactInformation;
