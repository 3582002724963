import { Link, useLoaderData } from "react-router-dom";
import {
  Button,
  Card,
  CardContent,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import UserProfile from "interfaces/userProfile";
import TextField from "@mui/material/TextField";
import React from "react";

function UserProfilePage() {
  const user = useLoaderData() as UserProfile;

  const formRef = React.useRef<HTMLFormElement>(null);

  const handleSubmit = () => {
    console.log("submit");
  };

  return (
    <>
      <div className="flex items-center mb-5">
        <Tooltip title="Back">
          <IconButton
            component={Link}
            to="/admin/users"
            sx={{ marginRight: 1 }}
            aria-label="delete"
          >
            <ArrowBackIcon />
          </IconButton>
        </Tooltip>

        <Typography variant="h6">{user.name}</Typography>
      </div>

      <Stack m="auto" maxWidth="450px" spacing={2}>
        <Card>
          <CardContent>
            <Typography variant="subtitle1">User Information</Typography>

            <TextField
              label="User ID"
              margin="normal"
              value={user.id}
              disabled
              required
              fullWidth
            />

            <form onSubmit={handleSubmit} ref={formRef}>
              <TextField
                label="Name"
                margin="normal"
                value={user.name}
                required
                fullWidth
              />
              <TextField
                label="Email"
                type="email"
                margin="normal"
                value={user.email}
                required
                fullWidth
              />
              <TextField label="Role" margin="normal" required fullWidth />
            </form>
          </CardContent>
        </Card>

        <Button
          variant="contained"
          onClick={() => {
            formRef.current?.requestSubmit();
          }}
        >
          Save
        </Button>
      </Stack>
    </>
  );
}

export default UserProfilePage;
