import React from "react";
import {
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
  Avatar as MUIAvatar,
} from "@mui/material";
import stringAvatar from "utils/stringAvatar";
import UserProfile from "interfaces/userProfile";
import { UserAPI } from "apis/userAPI";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "providers/authProvider";
import { useSnackbar } from "notistack";

interface Props {
  userProfile?: UserProfile;
}

function Avatar({ userProfile }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { removeUserProfile } = useAuth();

  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null,
  );

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogOut = () => {
    UserAPI.logout()
      .then(() => {
        enqueueSnackbar("Logged out successfully", { variant: "success" });
        removeUserProfile();
        navigate("/login");
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <>
      <Tooltip title="Open settings">
        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
          {userProfile ? (
            <MUIAvatar {...stringAvatar(userProfile.name)} />
          ) : (
            <MUIAvatar />
          )}
        </IconButton>
      </Tooltip>
      <Menu
        sx={{ mt: "45px" }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorElUser)}
        onClick={handleCloseUserMenu}
      >
        <MenuItem>
          <Link to="/admin/change-password">
            <Typography textAlign="center">Change Password</Typography>
          </Link>
        </MenuItem>

        <MenuItem onClick={handleLogOut}>
          <Typography textAlign="center">Log Out</Typography>
        </MenuItem>
      </Menu>
    </>
  );
}

export default Avatar;
