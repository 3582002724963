import { Stack, Typography } from "@mui/material";

interface CatalogueFileListItemProps {
  coverImage: string;
  title: string;
  description: string;
  link: string;
}

function CatalogueFileListItem({
  coverImage,
  title,
  description,
  link,
}: CatalogueFileListItemProps) {
  const handleClick = () => {
    window.location.href = link;
  };

  return (
    <Stack direction="row" spacing={2} justifyContent="center">
      <img
        src={coverImage}
        alt="Catalogue Cover"
        className="object-fill aspect-video w-60 sm:w-96 rounded-lg"
      />

      <div className="flex flex-col">
        <div className="font-bold text-3xl mb-4">{title}</div>

        <div className="flex-1">{description}</div>

        <Typography
          variant="button"
          className="mt-4 text-primary-200 hover:underline cursor-pointer"
          onClick={handleClick}
        >
          View Collection
        </Typography>
      </div>
    </Stack>
  );
}

export default CatalogueFileListItem;
