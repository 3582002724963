import * as React from "react";

import DashboardIcon from "@mui/icons-material/Dashboard";
import PeopleIcon from "@mui/icons-material/People";
import InventoryIcon from "@mui/icons-material/Inventory";
import InboxIcon from "@mui/icons-material/Inbox";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";

import RouterLink from "components/RouterLink";
import { useAuth } from "../../providers/authProvider";

export const MainListItems = () => {
  const { userProfile } = useAuth();

  return (
    <React.Fragment>
      <RouterLink to="/admin" text="Dashboard" icon={<DashboardIcon />} end />
      <RouterLink
        to="/admin/products"
        text="Products"
        icon={<InventoryIcon />}
      />

      {userProfile?.role === "admin" && (
        <RouterLink to="/admin/users" text="Users" icon={<PeopleIcon />} />
      )}

      <RouterLink to="/admin/inquiries" text="Inquiries" icon={<InboxIcon />} />
    </React.Fragment>
  );
};

export const secondaryListItems = (
  <React.Fragment>
    <RouterLink to="/" text="Exit" icon={<ExitToAppIcon />} />
  </React.Fragment>
);
