import { ProductAPI } from "apis/productAPI";

export const loader = async () => {
  try {
    const response = await ProductAPI.getAllProducts();

    return response.data.data;
  } catch (error) {
    console.error("Error fetching products: ", error);

    return null;
  }
};
