import { Navigate, Outlet } from "react-router-dom";

import LoadingPage from "containers/LoadingPage";
import AdminNavBar from "components/AdminNavBar";

import { useAuth } from "providers/authProvider";

function AdminLayout() {
  const { loaded, userProfile } = useAuth();

  if (!loaded) {
    return <LoadingPage />;
  } else if (!userProfile) {
    return <Navigate to="/login" />;
  }

  return (
    <>
      <AdminNavBar userProfile={userProfile}>
        <Outlet />
      </AdminNavBar>
    </>
  );
}

export default AdminLayout;
