import {
  Box,
  Button,
  Card,
  CardContent,
  Stack,
  Typography,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import ProductImageCard from "./productImageCard";
import { useState } from "react";
import { ProductAPI } from "apis/productAPI";

function NewProductForm() {
  const [images, setImages] = useState<File[]>([]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    images.forEach((image) => {
      formData.append("product[product_medias][]", image);
    });

    ProductAPI.createProduct(formData).then((response) => {
      console.log(response);
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <Stack m="auto" maxWidth="800px" spacing={2}>
        <Card>
          <CardContent>
            <Typography mb={2} variant="subtitle1">
              Product Information
            </Typography>

            <Stack spacing={3}>
              <TextField
                fullWidth
                size="small"
                id="outlined-basic"
                variant="outlined"
                label="Name"
                name="product[name]"
              />

              <TextField
                multiline
                rows={10}
                fullWidth
                size="small"
                id="outlined-basic"
                variant="outlined"
                label="Description"
                name="product[description]"
              />
            </Stack>
          </CardContent>
        </Card>

        <ProductImageCard images={images} setImages={setImages} />

        <Box display="flex" justifyContent="end" marginTop={2}>
          <Stack spacing={2} direction="row">
            <Button>Cancel</Button>
            <Button type="submit" variant="contained">
              Save
            </Button>
          </Stack>
        </Box>
      </Stack>
    </form>
  );
}

export default NewProductForm;
